import React from "react";
import {componentList} from "./componentList";


const ContentBuilder = ({content = []}) => {

    const layout = () => {
        return content.map(item => {
            if (componentList.hasOwnProperty(item.component) && item.settings.key) {
                const Component = componentList[item.component];
                const props = item.settings;
                return <Component key={props.key} {...props} />
            } else {
                console.warn(item.settings)
                return <></>
            }
        })
    }

    return <>{layout()}</>;

}

export default ContentBuilder;




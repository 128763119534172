import { useEffect, useState } from "react";


const useFetch = (url, defaultData = {}) => {

    const [value, setValue] = useState(defaultData);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(url);
            const json = await response.json();
            setValue(json);
        };
        fetchData().catch(e => setError(e));
    }, [url]);

    return [value, error];
};

export default useFetch;

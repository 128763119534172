import React from "react";
import styled from 'styled-components';
import {device} from "../util/global";


const FooterNav = styled.div`
    display: flex;
    align-items: center;
    background-color: #e2e2e2;
    margin-left: -15px;
    margin-right: -15px;
    padding: 15px 0 15px 0;
        
    @media ${device.tablet}{  
      height: ${(props) => (props.footerheight) ? props.footerheight : '125px'};
  }
`


const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
    @media ${device.tablet}{  
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

const FooterText = styled.p`
    font-size: 18px;
    line-height: 1.5em;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0;
`

const Title = styled(FooterText)`
    font-weight: 700;
`

const Text = styled(FooterText)`
    font-weight: 400;
`

const Link = styled.a`
    font-size: 18px;
    line-height: 1.5em;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 400;

`


const Footer = ({height = '150px'}) => {
    return <FooterNav footerheight={height}>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div id={"form"}></div>
                    <Center>
                        <Title>Graber Weine Messen GmbH </Title>
                        <Text>·</Text>
                        <Text>Hauptstrasse 17</Text>
                        <Text>·</Text>
                        <Text>3254 Messen</Text>
                        <Text>·</Text>
                        <Link href="tel:079 154 68 18">079 154 68 18 </Link>
                        <Text>·</Text>
                        <Link href="mailto:info@graberweine-messen.ch">info@graberweine-messen.ch</Link>
                    </Center>
                </div>
            </div>
        </div>
    </FooterNav>
}

export default Footer;

import React from "react";


const withFluidContainer = (Template) => {
    return (props) =>
        (<div className="container-fluid">
                <Template {...props}/>
            </div>
        )
}

export default withFluidContainer;

import React from "react";
import styled from "styled-components";
import useFetch from "../hooks/useFetch";
import {API} from "../data";
import Navigator from "../components/navigator";
import Footer from "../components/footer";


const White = styled.div`
    background-color: white;
    min-height: 100vh;
    width: 100vw;
`


const ContentWrapper = () => {
    const [websiteContent] = useFetch(`${API.baseUrl}/cockpit/api/singletons/get/navigation?token=${API.key}`, [])

    return (
        <div className="container-fluid">
            <div className="row">
                <White>
                    <Navigator entries={websiteContent.item}/>
                </White>
            </div>
            <Footer />
        </div>
    )
}

export default ContentWrapper

import asyncComponent from "./AsyncComponent";
import KassenTool from "../cockpitComponents/KassenTool";

export const componentList = {
    LargeTitle:  asyncComponent(() => import("../cockpitComponents/LargeTitle")),
    SubTitle: asyncComponent(() => import("../cockpitComponents/SubTitle")),
    LongText: asyncComponent(() => import("../cockpitComponents/LongText")),
    Gallery: asyncComponent(() => import("../cockpitComponents/Gallery")),
    Divider: asyncComponent(() => import("../cockpitComponents/Divider")),
    Konzerte: asyncComponent(() => import("../cockpitComponents/Konzerte")),
    FotoText: asyncComponent(() => import("../cockpitComponents/FotoText")),
    TitleAudio: asyncComponent(() => import("../cockpitComponents/TitleAudio")),
    Kontakt: asyncComponent(() => import("../cockpitComponents/Form")),
    Presse: asyncComponent(() => import("../cockpitComponents/Presse")),
    bigImageWithTitle: asyncComponent(() => import("../cockpitComponents/BigImageWithTitle")),
    ProduktBildText: asyncComponent(() => import("../cockpitComponents/ProduktBildText")),
    basicMap: asyncComponent(() => import("../cockpitComponents/BasicMap")),
    kassenTool: KassenTool,
};

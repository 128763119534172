import React from "react";
import styled from 'styled-components';
import {BrowserRouter as Router, Link, Route} from "react-router-dom";
import Coordinator from "./coordinator";
import logo from '../images/logo.png'
import {device} from "../util/global";
import MobileNavigation from "./mobileNavigation";

const FullWidth = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
`

const LogoPosition = styled.div`
  height: 100px;
  width: 200px;
  align-content: flex-start;
  background-image: url(${logo});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
`

const RightSideEvenly = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const EntriesWithPadding = styled(Link)`
  margin: 1rem; 
  line-height: 4em;
  font-size: 18px;
  position: relative;
  color: black;
  text-transform: uppercase;
  cursor: pointer;
  display: none;
   
  @media ${device.tablet} {  
    display: block;
  }
  
  &::after {
    position: absolute;
    bottom: 25px;
    display: block;
    content: '';
    height: 1px;
    width: 0;
    background-color: black;
    left: 50%;
    -webkit-transform: translate(-50%,0) translateZ(0);
    transform: translate(-50%,0) translateZ(0);
    -webkit-transition: width .3s cubic-bezier(0.27,0.62,0.43,1.01);
    transition: width .3s cubic-bezier(0.27,0.62,0.43,1.01);
    z-index: 150;
  }
  
  &:hover {
    text-decoration: none;
    color: black;
    
    &::after{
      width: 100% 
    }
  }
`

const MobileEntriesWithPadding = styled(Link)`
  display: flex;
  line-height: 1.4em;
  font-size: 14px;
  color: black;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
   
  @media ${device.tablet} {  
    display: none;
  }
`


const LogoEntry = styled(Link)`
  margin: 1rem; 
  line-height: 4em;
  font-size: 18px;
  position: relative;
  color: black;
  text-transform: uppercase;
  cursor: pointer;
  background-color: white;
  z-index: 50;
`
const GiveMorePadding = styled.div`
  margin-bottom: 20px;
`

const Navigator = ({entries = []}) => {

    const buildEntries = () => entries.map(item => <EntriesWithPadding to={item.value}
                                                                       key={item.value}>{item.value}</EntriesWithPadding>)
    const buildMobile = () => entries.map(item => <MobileEntriesWithPadding to={item.value}
                                                                            key={item.value}>{item.value}</MobileEntriesWithPadding>)
    const [open, setOpen] = React.useState(false)
    return (
        <>
            <Router>
                <FullWidth>
                    <LogoEntry to={'/'} key={'asdfasdf'}><LogoPosition/></LogoEntry>
                    <RightSideEvenly>
                        {buildEntries()}
                        <MobileNavigation open={open} fn={setOpen}/>
                    </RightSideEvenly>
                </FullWidth>
                {open && <GiveMorePadding>{buildMobile()}</GiveMorePadding>}
                <Route path="/" exact component={Coordinator}/>
                <Route path="/:id" exact component={Coordinator}/>
            </Router>
        </>
    )
}

export default Navigator;

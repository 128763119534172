import React, {useEffect, useRef} from "react";
import withFluidContainer from "../hoc/withFluidContainer";


const KassenTool = (props) => {
    const textInput = useRef(null);


    const useSystem = url => {
        useEffect(() => {
            const script = document.createElement('script');

            script.src = url;
            script.async = true;

            textInput.current.appendChild(script);
            return () => {
                // document.body.removeChild(script);
            }
        }, [url]);
    };

    useSystem("https://www.gastronavi.de/restaurants/56963/scripts/reservation")

    return <div ref={textInput}/>;
}

export default withFluidContainer(KassenTool);
